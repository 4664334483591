import { Avatar, Button, Dropdown, Menu, Spin } from 'antd';
import { Layout, Row } from 'antd/es';
import React, { Suspense, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SideBarComponent } from '../../../components/sidebar/sidebar.component';
import { AuthContext } from '../../../context/auth-context.provider';
import { PagesRouter } from '../../router/pages.router';

export const MainLayoutPage: React.FC = function MainLayoutPage() {
  const { logout, user, getAndSetUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const localizationPrefix = 'mainLayoutPage';
  const location = useLocation();

  useEffect(() => {
    getAndSetUser();
  }, [getAndSetUser]);
  const headerMenuItems = [
    {
      key: 1,
      label: (
        <Button type="link" onClick={() => logout()}>
          {t(`${localizationPrefix}.logout`)}
        </Button>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }} className="bg-mai">
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {}}
      >
        <div className="text-center mt-3">
          <img src="/images/logo-white.png" className="img-fluid logo mx-auto mb-3" alt="cat-logo" />
          <SideBarComponent />
        </div>
      </Layout.Sider>
      <Layout className="site-layout shadow-sm">
        <Layout.Content
          className="site-layout-background p-1"
          style={{
            minHeight: 280,
          }}
        >
          <Row className="mb-3" justify="space-between" align="middle">
            <h2 className="mb-0 main-page-title">
              {location.pathname.split('/')[1] === 'plans' ? 'Shop' : t(location.pathname.split('/')[1])}
            </h2>
            <Row justify="space-between" align="middle">
              {!user ? (
                <></>
              ) : (
                <Dropdown overlay={<Menu items={headerMenuItems} />} placement="bottomRight" arrow>
                  <Avatar style={{ backgroundColor: 'red', verticalAlign: 'middle' }} size="large">
                    {user.firstName[0].toUpperCase()}
                    {user.lastName[0].toUpperCase()}
                  </Avatar>
                </Dropdown>
              )}
            </Row>
          </Row>
          <Suspense fallback={<Spin />}>{user && <PagesRouter user={user} />}</Suspense>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

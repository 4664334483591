import { message } from 'antd';
import { createContext, useCallback, useMemo, useState } from 'react';
import { IsErrorResDto } from '../api/error-res.dto';
import { ServerController } from '../api/server.controller';
import type { UserResDto } from '../api/shared/res/user-res.dto';
import { AccountTypeEnum } from '../types/account-type.enum';
import { UserToken } from '../types/user-token';
import { AuthStorageUtility } from '../utilities/auth-storage.utility';
import { JwtUtility } from '../utilities/jwt.utility';

interface AuthState {
  isAuthenticated: boolean;
  user: UserResDto | null;
  token: string | null;
}
interface AuthActions {
  checkIfJwtIsValid: () => Promise<void>;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  logout: () => void;
  checkAuthToken: () => void;
  authenticateViaTokenUrl: (url: string) => Promise<void>;
  getAndSetUser: () => Promise<void>;
  isInRole(accountType: AccountTypeEnum): boolean;
}
const AuthContextPartial: React.Context<Partial<AuthState & AuthActions>> = createContext({});
export const AuthContext = AuthContextPartial as React.Context<AuthState & AuthActions>;

export const AuthProvider = (props: any): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<UserToken | null>(null);
  const [user, setUser] = useState<UserResDto | null>(null);

  const checkIfJwtIsValid = useCallback(async () => {
    try {
      const response = await ServerController.Auth.authenticate();
      if (response.status === 200) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {}
  }, []);

  const getAndSetUser = useCallback(async () => {
    try {
      const response = await ServerController.SharedUser.get();
      if (!IsErrorResDto(response)) {
        setUser(response.data);
      } else {
        // handle error
      }
    } catch (error) {}
  }, []);

  const decodeJwtToken = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    setUserToken(JSON.parse(jsonPayload));
  };

  const authenticateViaTokenUrl = useCallback(async (url: string) => {
    const response = await ServerController.PublicTokenLogin.get(url);
    if (!IsErrorResDto(response)) {
      AuthStorageUtility.saveToken(response.data.jwt);
      const user = JwtUtility.decode(response.data.jwt);
      setIsAuthenticated(true);
    } else {
      const messageText = Array.isArray(response.message) ? response.message.join('\n') : response.message;
      alert(messageText);
      message.error(messageText);
    }
  }, []);

  const authMemo = useMemo(
    () => ({
      isAuthenticated,
      token: AuthStorageUtility.getToken(),
      logout: () => {
        AuthStorageUtility.deleteToken();
        setIsAuthenticated(false);
      },
      checkAuthToken: () => {
        const token = AuthStorageUtility.getToken();
        if (token) {
          decodeJwtToken(token);
          return true;
        } else {
          authMemo.logout();
        }
      },
    }),
    [isAuthenticated],
  );

  const isInRole = useCallback(
    (accountType: AccountTypeEnum): boolean => {
      return user?.roles.includes(accountType) ?? false;
    },
    [user?.roles],
  );

  return (
    <AuthContext.Provider
      value={{
        ...authMemo,
        setIsAuthenticated,
        checkIfJwtIsValid,
        getAndSetUser,
        user,
        authenticateViaTokenUrl,
        isInRole,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

import { notification } from 'antd';
import axios from 'axios';
import { GetAndInitI18N } from '../i18n/i18n.config';
import { AuthStorageUtility } from '../utilities/auth-storage.utility';

export const SetupAxiosErrorInterceptor = function SetupAxiosErrorInterceptor() {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },

    function (exception) {
      const errResponse = exception.response;
      if (errResponse != null && errResponse.data != null) {
        const resConfig = errResponse.config;

        // This case handles missing token or expired token issue.
        // In that case user will be taken to login page and no notification is needed.
        if (resConfig?.url?.endsWith('/v1/administrators') && errResponse.status === 403) {
          return Promise.reject(exception);
        }

        const { statusCode, message } = errResponse.data;

        const i18n = GetAndInitI18N();
        const translationKey = `errorStatusCodes.${statusCode}`;
        let translation = "";
        const messages = Array.isArray(message) ? message.join('\n') : message;
        if (i18n.exists(translationKey)) {
          translation = i18n.t(translationKey);
        }

        const messageTranslated = translation +'\n\nREASON::' + messages;

        notification.error({
          message: `Status code: ${statusCode}`,
          description: messageTranslated,
          duration: 5,
        });
      } else if (exception.request != null) {
        notification.error({
          message: 'Network Error',
          description:
            'Connection to the API could not be established.\nIt may be temporarily unavailable or you may have issues with your internet connection.',
          duration: 5,
        });
      } else if (exception.message != null) {
        notification.error({
          message: 'Network Error',
          description: exception.message,
          duration: 5,
        });
      }

      return Promise.reject(exception);
    },
  );
};

export const SetupAxiosAuthorizationInterceptor = function SetupAxiosAuthorizationInterceptor() {
  axios.interceptors.request.use((config: any) => {
    const authToken = AuthStorageUtility.getToken();
    if (authToken != null) {
      config.headers.Authorization = `${authToken}`;
    }
    return config;
  });
};

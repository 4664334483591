import { DeleteOutlined, EditOutlined, MailOutlined, MenuOutlined, UserAddOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Result } from 'antd';
import { Card, Skeleton, message } from 'antd/es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { UserResDto } from '../../api/shared/res/user-res.dto';
import { successMessage } from '../../components/antd-notifications/antd-notifications';
import { AuthContext } from '../../context/auth-context.provider';
import { StringUtility } from '../../utilities/string.utility';
import { AvatarComponent } from '../account/avatar';
import UserTotalCountComponent from './components/user-total-count.component';
import './style.css';

export const UserPage = function UserPage() {
  const navigate = useNavigate();
  const [athletes, setAthletes] = useState<UserResDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const { user, getAndSetUser } = useContext(AuthContext);

  const load = useCallback(async () => {
    setLoading(true);
    const response = await ServerController.CoachAthlete.get();
    if (!IsErrorResDto(response)) {
      setAthletes(response.data);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const sendEmailConfirmation = async (userId: string) => {
    const response = await ServerController.CoachAthleteEmailRegistration.post({ userId });
    if (!IsErrorResDto(response)) {
      message.open(successMessage(t('userPage.confirmationEmailSent')));
    }
  };

  const deleteUser = async (athleteId: string) => {
    const isConfirmed = window.confirm(t(`userPage.confirmDelete`));
    if (isConfirmed) {
      const response = await ServerController.CoachAthlete.delete(athleteId);
      if (!IsErrorResDto(response)) {
        message.open(successMessage(t(`userPage.userDeleted`)));
        await getAndSetUser();
        await load();
      }
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-end mb-3 align-items-center ">
        <UserTotalCountComponent />

        <Button onClick={() => navigate('/users/add')} type="primary" icon={<UserAddOutlined />}>
          Add user
        </Button>
      </div>
      <>
        <Skeleton avatar title={false} loading={loading} active>
          {athletes.length > 0 ? (
            athletes.map((athlete, i) => {
              return (
                <Card className="rounded shadow-sm mt-1" key={i}>
                  <div className="d-flex align-items-center justify-space-between my-2">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="me-1">
                          {' '}
                          {!athlete.base64Image ? (
                            <AvatarComponent user={athlete} size={{ sm: 12, xxl: 64, xl: 64, md: 23, xs: 12 }} />
                          ) : (
                            <Avatar src={athlete.base64Image} size={{ sm: 32, md: 64, lg: 64 }} />
                          )}
                        </div>
                        <div>
                          <div>
                            {`${StringUtility.capitalizeFirstLetter(
                              athlete.firstName,
                            )} ${StringUtility.capitalizeFirstLetter(athlete.lastName)}`}
                          </div>
                          <i className="small-font">
                            <b>{athlete.email}</b>
                          </i>
                        </div>
                      </div>
                    </div>
                    <div className="text-end">
                      <Dropdown
                        className="d-md-none"
                        menu={{
                          items: [
                            {
                              key: '1',
                              label: (
                                <div className="user-dropdown-item" onClick={() => navigate(`/users/${athlete.id}`)}>
                                  <span>
                                    <EditOutlined />
                                    {'  '}
                                    {t(`userPage.editUserDetails`)}
                                  </span>
                                </div>
                              ),
                            },
                            {
                              key: '2',
                              label: (
                                <div className="user-dropdown-item" onClick={() => sendEmailConfirmation(athlete.id)}>
                                  <span>
                                    <MailOutlined />
                                    {'  '}
                                    {t(`userPage.sendRegistrationEmail`)}
                                  </span>
                                </div>
                              ),
                            },
                            {
                              key: '3',
                              label: (
                                <div
                                  className="user-dropdown-item danger"
                                  onClick={() => {
                                    deleteUser(athlete.id);
                                  }}
                                >
                                  <span style={{ color: 'red' }}>
                                    <DeleteOutlined />
                                    {'  '}
                                    {t(`userPage.deleteUser`)}
                                  </span>
                                </div>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                      >
                        <Button icon={<MenuOutlined />} type="text" />
                      </Dropdown>
                      <div className="d-none d-md-block">
                        <div className="user-dropdown-item" onClick={() => navigate(`/users/${athlete.id}`)}>
                          <span>
                            <EditOutlined />
                            {'  '}
                            {t(`userPage.editUserDetails`)}
                          </span>
                        </div>
                        <div className="user-dropdown-item" onClick={() => sendEmailConfirmation(athlete.id)}>
                          <span>
                            <MailOutlined />
                            {'  '}
                            {t(`userPage.sendRegistrationEmail`)}
                          </span>
                        </div>
                        <div
                          className="user-dropdown-item danger"
                          onClick={() => {
                            deleteUser(athlete.id);
                          }}
                        >
                          <span style={{ color: 'red' }}>
                            <DeleteOutlined />
                            {'  '}
                            {t(`userPage.deleteUser`)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })
          ) : (
            <Result status="warning" title="You haven't added any athletes yet!" />
          )}
        </Skeleton>
      </>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import type { PaymentMethodResDto } from '../../../api/coach/res/coach-payment-method-res.dto';
import { CardSelector } from '../../../constants/card-svgs/credit-cards';

interface Props {
  paymentMethod: PaymentMethodResDto;
}
export const PaymentMethodComponent: React.FC<Props> = function PaymentMethodComponent({ paymentMethod }) {
  const { t } = useTranslation();
  return (
    <div>
      <b>{t('accountPage.components.paymentMethod.title')}:</b>
      <div className="d-flex align-items-center">
        <div className="me-2"> {CardSelector(paymentMethod.brand) ?? ''}</div>
        <div>**** **** **** {paymentMethod.last4 ?? ''}</div>
      </div>
      <div>
        {t('accountPage.components.paymentMethod.exp')}: {paymentMethod.exp_month ?? ''}/{paymentMethod.exp_year ?? ''}
      </div>
    </div>
  );
};
